;(function(app, $) {
	var data = $('#js-app_dynamic_data').data(),
		bodyDynamicClass = '';
	

	// current customer info
	app.currentCustomer = app.currentCustomer || {};
	app.currentCustomer.isAuthenticated = function () {
		return data && data.isUserAuthenticated;
	};
	app.currentCustomer.isRegistered = function () {
		return data && data.isUserRegistered;
	};
	app.currentCustomer.isSubscribed = function () {
		return data && data.isUserSubscribed;
	};
	app.currentCustomer.getPreference = function (key, defaults) {
		return (data && data[key]) || defaults;
	};
	app.currentCustomer.setUserClicksNumber = function ( userClicksNumber ) {
		$.cookie("userClicksNumber", userClicksNumber, { path: '/' });
	};
	app.currentCustomer.getUserClicksNumber = function () {
		return +$.cookie("userClicksNumber") || 0;
	};
	app.currentCustomer.getShippingCountryName = function () {
		return data && data.shippingCountry;
	}
	app.currentCustomer.getCurrentPipeline = function () {
		return data && data.currentPipeline;
	}
	
	app.currentCustomer.getCurrentQueryString = function () {
		return data && data.currentQueryString;
	}
	
	app.currentCustomer.setUserClicksNumber( app.currentCustomer.getUserClicksNumber() + 1 );

	if (app.configs.firstVisit.expiresOnExit) {
		try {
			cookie = $.cookie(app.configs.firstVisit.keyName) ? JSON.parse($.cookie(app.configs.firstVisit.keyName)) : {};
			if (typeof cookie !== "object") cookie = {};
		} catch (e) {
			cookie = {};
		}
		bodyDynamicClass += cookie[window.name] && cookie[window.name]['first_visit'] ? '' : 'm-firstvisit ';
	} else {
		bodyDynamicClass += $.cookie(app.configs.firstVisit.keyName) ? '' : 'm-firstvisit ';
	}
	bodyDynamicClass += app.currentCustomer.isAuthenticated() ? 'm-authenticated_user ' : 'm-not_authenticated_user ';
	
	$('body').addClass(bodyDynamicClass);
}(window.app = window.app || {}, jQuery));
